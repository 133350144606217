/*
Template Name: Lexa - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 2.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "theme-color";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/root";
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/alerts";
@import "custom/components/popover";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datatable";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/flot";
@import "custom/plugins/morris";
@import "custom/plugins/chartist";
@import "custom/plugins/c3";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/timeline";
@import "custom/pages/directory";
@import "custom/pages/chat";
@import "custom/pages/kanbanboard";

.card {
    position: relative;
    background-color: #fff;
    margin-bottom: 1.25rem;
    border: 1px solid #edf2f9;
    border-radius: .25rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}
.white{
    color: #fff !important;
}
.plus-div{
    position: relative;
    padding-right: 40px;
}
.plus_btn{
    float: right;
    position: absolute;
    right: 0;
    top: 0;
}
a.btn{
    color: #fff !important;
}
.search-form{
    border: 1px solid #eee !important;
    margin: 15px 0;
    padding: 20px 10px;
    border-radius: 15px;
    padding-bottom: 30px;
}
.table-holder{
    overflow-x: scroll;
}
.margin-right{
    margin-right: 15px;
}
.modal{
    max-width: calc(100% - 30px);
}
.renewal td{
    background-color: #ff00000d;
}
.switch_ul {
    margin: 15px 0;
    border: 1px solid #eee;
    padding: 15px;
    border-radius: 10px;
}
.switch_ul li {
    display: inline-block;
    margin: 0 15px;
}
.switch_btn {
    color: #fff;
    padding: 8px 25px;
    border: none;
    outline: none;
    border-radius: 5px;
}
.switch_btn.active{
    background-color: #7cb328;
}
.success-box{
    background-color: #7cb328 !important;
}
.danger-box{
    background-color: #f3a4a4fb !important;
}
.danger-light-box{
    background-color: #daacac !important;
}
table{
    margin-top: 15px;
}
.table th {
    font-weight: 700;
    background: #eee;
    border-right: 1px solid #fff;
}
@media (max-width:992px) {
    .modal-dialog{
        min-width: 100% !important;
    }
}